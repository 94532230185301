import React from "react"
import { Link } from "gatsby"
import Navbar from "../components/auth/navbar"
import { PageWrapper, TwoColumn, Headline, SectionHeader, PageSqueeze } from "../styles/lib"
import { login, logout } from "../utils/auth"
import examImage from "../img/exams.svg"
import studyImage from "../img/studying.svg"
import teacherImage from "../img/teacher.svg"
import progressImage from "../img/progress.svg"

export default () => {
  return(
    <>
    <Navbar />
    <PageWrapper initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <TwoColumn>
        <div>
          <Headline>The <span>best students know</span> what they don't know</Headline>
          <a href="#smarter">Learn More</a>
        </div>  
      <img src={examImage} alt="An exam"/>
      </TwoColumn>
      <PageSqueeze>
      <TwoColumn>
        <SectionHeader id="smarter">
          <h2>Study <span>smarter</span>, not harder</h2>
          <p>It doesn't take countless, mindless hours to learn material for a class. It takes focused effort and engaging ways of interacting with the information. With Student Centric, your students have a front-row seat that lets stakeholders track their progress and see how they stack up to their classmates.</p>
        </SectionHeader>  
      <img src={studyImage} alt="An exam"/>
      </TwoColumn>
      <TwoColumn>
      <img src={teacherImage} alt="An exam"/>
        <SectionHeader>
          <h2><span>Engage students</span> while building knowledge</h2>
          <p>If you're a teacher who's tired of students not completing homework or not having mastered the basic building blocks of a concept, Student Centric is for you. Create quizzes that allow students to earn XP. Our easy-to-use interface lets you get up and running and working with your students in no time.</p>
        </SectionHeader>  
      </TwoColumn>
      <TwoColumn>
        <SectionHeader>
          <h2><span>Achieve and compete</span> with classmates</h2>
          <p>With a leaderboard in each class, students can see how they stack up to their classmates. Immediate feedback allows students to track their progress and see their growth from start to finish. Advanced analytics and metrics that keep them focused without overdoing it.</p>
        </SectionHeader>  
      <img src={progressImage} alt="An exam"/>
      </TwoColumn>
      <PageSqueeze style={{ placeItems: `center`, textAlign: `center` }}>
        <Headline>Ready to see what you don't know?</Headline>
        <a onClick={e => {
                    login()
                    e.preventDefault()
                  }}>Get started!</a>
      </PageSqueeze>
      </PageSqueeze>
    </PageWrapper>
    </>
  )
        }
